/* sidebar code */
.sidebar{
	width: 100%;
	min-width: 270px;
	max-width: 100%;
	height: 100%;
	position: fixed;
	top: 0px;
	overflow:auto !important;
	right: -100%;
	z-index: 20;
	transition: 0.2s ease;
	background: #BC6F04;
	color: #000;
}
.sidebar .close-icon{
	font-size: 25px;
	margin-right: 0px;
	color: #fff;
}
.open-cart{
	right: 0px !important;
}
.open-cartt{
	display:none;
}
.open-cart:before{
	content: '';
	position: fixed;
	top: 0px;
	height: 70vh;
	left: 0px;
	right: 0px;
	bottom: 0px;
	pointer-events: none;
	z-index: -1;
}
.navbar-sidebar {
	padding: 25px;
	margin-top: 65px;
}
.navbar-sidebar ul li{
	list-style: none;
	background: #FAAF50;
    color: #fff;
	text-align: center;
	padding: 10px 15px;
	margin-bottom: 20px;
	border-radius: 0px;
	font-weight: 400;
}
.navbar-sidebar ul li a{
	text-decoration: none;
	color: #ffffff;
}
.navbar-sidebar ul{
    padding: 0;
}
.sidebar .closse{
	position: absolute;
	top: 10px;
	right: 20px;
	color: #000000;
}
.sidebar .active {
	color: #272B73;
}