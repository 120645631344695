@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
body{
  overflow-x: hidden;
	/* background-color: #272B73 !important; */
	font-family: "Raleway", sans-serif !important;
}
*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
ul li{
  list-style: none;
}
a{
  text-decoration: none;
}
img{
	width: 100%;
}



























