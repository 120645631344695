@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
.container-main{
	width: 90%;
	margin: auto;
}
.logo-img{
	width: 170px;
}
.navbar-main{
	padding: 20px 0px;
	position: absolute;
	width: 100%;
	top: 0;
	z-index: 100;
}
.shop-img{
	width: 50px !important;
	display: table !important;
	margin: auto;
	padding-bottom: 15px;
}

.aboutus-section{
	background-image: url('./Assets/new/1\ \(2\).png');
	background-size: cover;
	background-repeat: no-repeat;
}

.items-nav ul{
	margin-bottom: 0px !important;
}
.items-nav{
	display: flex;
}
.items-nav ul li a{
	text-decoration: none !important;
	color: #975600;
	font-weight: 600;
}
.items-nav ul{
	display: flex;
}
.items-nav ul li{
	padding: 0px 20px;
}
.navbar-home{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.navbar-main{
	/* position: absolute; */
	width: 100%;
}

.footer-inner{

}
.footer-main{
	padding: 60px 0px 30px 0px;
	background-color: #7c4800;
	cursor: pointer;
}
.footer-logo{
	width: 80%;
}
.footer-sub-text{
	color: #fff;
	font-size: 14px;
	line-height: 1.4;
	padding-top: 40px;
}
.title-footer{
	color: #ffffff;
	padding-bottom: 15px;
}
.footer-inner ul{
	padding-left: 0px !important;
	margin-bottom: 0px !important;
}
.footer-inner ul li a{
	color: #ffff;
	text-decoration: none;
}
.footer-inner ul li {
	padding-bottom: 15px;
}
.footer-inner iframe{
	width: 100%;
}
.icon-footer{
	color: #fff;
}
.icon-footer i{
	font-size: 20px !important;
}
.social-media i{
	color: #fff;
	font-size: 25px;
	margin-right: 15px;
}
.details{
	padding-top: 20px;
}
.footer-copyright p{
	text-align: center;
	color: #fff;
	padding-top: 30px;
	margin-bottom: 0px !important;
}
.products-img{
	width: 100%;
}
.products{
	position: sticky;
	top: 0;
	background-color: #ffffff;
}
.serv-title{
	padding-top: 30px;
}
.products-mob{
	display: none;
}
.menu-icon{
	color: #975600;
	font-size: 30px;
}


.bg-mob{
	background-image: url('./Assets/Latest/bg3.avif');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	/* position: absolute; */
	width: 100%;
}
.bg-mob1{
	background-image: url('./Assets/Latest/bg1.svg');
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	/* position: absolute; */
	width: 100%;
}
.sub-hero{
	color: #FAAF50;
	/* text-align: center; */
	font-size: 17px;
	font-weight: 300;
	padding-top: 1%;
	width: 50%;
	padding-bottom: 30px;
}
.heroo{
	/* text-align: center; */
	color: #FAAF50;
	line-height: 1.0;
	font-size: 57px;
	margin-top: 0px !important;
	margin-bottom: 0px !important;
	font-family: "Abril Fatface", serif;
}
.item-content{
	width: 90%;
	margin: auto;
	padding: 18% 0px 15% 0px;
}
.btn-home-download{
	font-size: 16px !important;
	border-radius: 14px !important;
	background-color: none !important;
	border: 2px solid #FAAF50 !important;
	font-weight: 400 !important;
	color: #FAAF50 !important;
	margin-left: 20px !important;
	text-decoration: none !important;
	padding: 9px 20px 12px 20px !important;
}
.btn-home-explore{
	font-size: 16px !important;
	width: max-content;
	margin: auto;
	font-weight: 500 !important;
	background-color: #BC6F04;
	text-decoration: none !important;
	color: #ffffff !important;
	padding: 9px 40px 12px 40px !important;
	border-radius: 14px !important;
}
.marquee {
  background-color: #FAAF50;
  /* overflow: hidden;
  white-space: nowrap; */
  width: 100%;
  padding: 3% 0;
}

.moving-text {
}
.text-moving{
	color: #fff;
	margin-bottom: 0px !important;
	padding-right: 13%;
}
.moving-text p{
	margin-bottom: 0px !important;
}
.aboutus-section{
	padding: 80px 0px 80px 0px;
}
.about-image{
	width: 90%;
	margin: auto;
	border-radius: 20px;
	border: 2px solid #b2b3b2;
}
.title-about-us{
	color: #BC6F04;
	font-weight: 400;
}
.title-about-us-main{
	font-weight: 700 !important;
}
.sub-t-about-us{
	color: grey;
	padding-bottom: 20px;
}
.border-dv{
	background-color: grey;
	padding: 2px;
	margin-bottom: 30px ;
}
.btn-home-download-a{
	font-size: 16px !important;
	border-radius: 14px !important;
	background-color: none !important;
	border: 1px solid #BC6F04 !important;
	font-weight: 400 !important;
	color: #BC6F04 !important;
	margin-left: 20px !important;
	text-decoration: none !important;
	padding: 9px 20px 12px 20px !important;
}
.why-shop{
	padding: 50px 0px 70px 0px;
}
.shop-with-txt{
	text-align: center;
	font-weight: 700;
	color: #FAAF50;
	font-size: 36px;
	padding-bottom: 30px;
}
.bg-shop{
	background-color: #BC6F04;
	display: flex;
	justify-content: space-between;
	padding: 50px 50px;
	border-radius: 120px;
}
.value-img{
	width: 70%;
	left: 0;
	top: -80px;
	right: 0;
	margin: auto;
	position: absolute;
	border-radius: 50px;
}
.values-sub-img{
	position: relative;
}
.shop-half{
	width: 30.333%;
}
.shop-title{
	color: #FAAF50;
	text-align: center;
	font-weight: 600;
	padding-bottom: 8px;
	font-size: 21px;
}
.shop-sub-title{
	color: rgb(255, 255, 255);
	font-size: 15px;
	text-align: center;
	width: 90%;
	margin: auto;
}
.values{
	background-color: #BC6F04;
	padding: 60px 0px 60px 0px;
	margin-top: 50px;
}
.our-values{
	font-size: 40px;
	font-weight: 800;
	color: #fff;
	padding-bottom: 10px;
}
.our-values-sub{
	font-size: 16px;
	color: #fff;
	padding-bottom: 20px;
}
.our-values-title{
	font-size: 25px;
	font-weight: 600;
	color: #fff;
}
.exclusive{
	background-color: #FAAF50;
	padding: 30px 0px;
}
.exctop{
	margin-top: 130px;
}
.exclusive-text{
	color: #fff;
	font-weight: 700;
}
.exclusive-sub-text{
	color: #fff;
}
.exclusv-half{
	width: 70%;
}
.img-ai{
	padding-left: 75px;
}
.exclusive-box{
	display: flex;
	/* justify-content: space-between; */
}
.offer-slider{
	width: 90% !important;
	margin: auto;
	display: table !important;
	margin-bottom: 50px;
	border-radius: 25px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.offer-img-sldr{
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	width: 100%;
}
.item-namee{
	font-size: 24px;
	text-transform: uppercase;
	/* padding-top: 20px; */
}
.wh{
	text-align: center;
	font-weight: 800;
	padding-bottom: 45px;
	font-size: 30px;
}
.item-subb{
	font-size: 16px;
	padding-bottom: 15px;
}
.items-boxx{
	padding: 20px 20px 47px 20px;
}
.what-we-offer{
	padding: 90px 0px 80px 0px;
}
.btn-home-shop{
	font-size: 16px !important;
	width: max-content;
	margin: auto;
	font-weight: 600 !important;
	background-color: #BC6F04;
	text-decoration: none !important;
	color: #ffffff !important;
	padding: 11px 25px 12px 25px !important;
	border-radius: 19px !important;
}
.b{
	background-image: url('./Assets/Latest/bg4.jpg');
}
.c{
	background-image: url('./Assets/new/banner2.svg');
}
.common-banner{
	background-repeat: no-repeat;
	background-size: cover;
	padding: 14% 0px 7% 0px;
}
.sub-title-banner{
	color: #FAAF50;
}
.banner-title{
	color: #FAAF50;
	font-weight: 700;
	margin-bottom: 0px !important;
}
.products-page-div{
	padding: 70px 0px;
}
.c{
	background-image: url('./Assets/Latest/bg1.svg');
}
.services-inner{
	background-color: #FAAF50;
	padding: 30px 30px;
	border-radius: 50px;
}
.service-icon{
	width: 65px;
}
.servc-sub-name{
	color: #2A2D77;
	line-height: 1.3;
}
.servc-name{
	padding-top: 20px;
	font-weight: 700;
	color: #2A2D77;
}
.srvc{
	border: 1px solid #ffffff;
	padding: 20px;
	border-radius: 20px;
	margin-bottom: 25px;
}
.d{
	background-image: url('./Assets/Latest/image\ 536.png');
}
.e{
	background-image: url('./Assets/Latest/image\ 535.png');
}
.contactimg{
	width: 55px;
}
.mail-box{
	margin-bottom: 30px;
	background-color: #ffefd8;
	padding: 20px 20px;
	display: flex;
	align-items: center;
	border-radius: 10px;
}
.contact-paged{
	padding: 70px 0px;
}
.dettls{
	padding-left: 20px;
}
.text-locate{
	color: #BC6F04;
	text-transform: capitalize !important;
	font-size: 21px;
}
.contact-box-form label{
	font-size: 16px;
	color: #BC6F04;
	padding-bottom: 4px;
}
.contact-box-form .form-control{
	border: 1px solid #BC6F04 !important;
	padding: 7px 15px;
}
.contact-box-form .form-group{
	padding-bottom: 20px;
}
.submit-btn{
	background-color: #BC6F04 !important;
	padding: 9px 25px !important;
	color: #fff !important;
}

.float{
	position:fixed;
	width: 52px;
	bottom:40px;
	right:40px;
  z-index:100;
	text-decoration: none !important;
}

.my-float{
	margin-top:16px;
}
.text-locate a{
	color: #7c4800 !important;
	text-decoration: none !important;
}
.social-media a{
	text-decoration: none !important;
}




@media (min-width:2000px){
	.container-main{
		width: 65% !important;
		margin: auto;
	}
	.item-content{
		width: 65%;
		margin: auto;
		padding: 18% 0px 15% 0px;
	}
}


@media (min-width:1750px){
	.container-main{
		width: 70% !important;
		margin: auto;
	}
	.item-content{
		width: 70%;
		margin: auto;
		padding: 18% 0px 15% 0px;
	}
}
@media (max-width:991px){
	.products-img{
		display: none;
	}
	.common-banner {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20% 0px 8% 0px;
	}
	.about-image {
    width: 80%;
    display: table;
	}
	.about-inner-box {
		padding-bottom: 30px;
	}
	.contact-address-box{
		padding-bottom: 35px;
	}
	.sub-hero{
		padding-top: 2%;
	}
	.value-img{
		width: 98%;
	}
	.contact-main {
    width: 95%;
	}
	.hidedesk{
		display: block !important;
	}
	.moby{
		display: block;
	}
	.about-image-home {
    width: 72%;
    margin: auto;
    display: table;
	}
	.contact-boxxx {
    margin-bottom: 27px;
	}
	.mobno{
		display: none;
	}
	.show-desk{
		display: none;
	}
	.contact-main button {
    display: table;
    margin-left: auto;
	}
	.products-mob{
		display: block;
	}
	.items-nav ul{
		display: none;
	}
	.home-img {
    margin-top: 60px;
	}
	.menu-icon{
		display: block;
	}
	.about-section {
    background-color: #EBFFD8;
    min-height: 100vh;
    padding-top: 43px;
    position: static;
	}
	.quality-box {
    min-height: 350px;
    margin-bottom: 40px;
	}
	.quality {
    min-height: 100vh;
    position: static;
    padding-top: 40px;
	}
	.menu-icon{
		font-size: 30px;
	}
	.hide-desk{
		display: block;
	}
}

@media (max-width:740px){
	.item-content {
    position: absolute;
    top: 24%;
    left: 4%;
		width: 89%;
		margin: auto;
    transform: translate(-50%, -50%);
    /* Your content styles */
	}
	.common-banner {
		padding: 23% 0px 9% 0px;
	}
	.aboutus-section {
    padding: 60px 0px 80px 0px;
	}
	.bg-mob{
		position: relative;
		}
	.sub-hero{
		font-size: 18px;
		line-height: 1.4;
		width: 98%;
	}
	.value-img {
    position: static;
	}
	.value-img {
		width: 98%;
		margin-top: 35px;
	}
	.img-ai {
    padding-left: 20px;
    width: 73px;	
	}
	.exclusive-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
	}
	.bg-shop {
    display: block;
	}
	.shop-half {
    width: 100%;
		padding-bottom: 30px;
	}
	.border-dv {
    padding: 2px;
    margin-bottom: 50px;
	}
}

@media (max-width:600px){
	.logo-img {
		width: 170px !important;
	}
	
	.mail-box{
		display: block;
	}
	.contactimg{
		width: 55px !important;
		margin: auto !important;
		display: table !important;
	}
	.exctop{
		margin-top: 0px;
	}
	.services-inner{
		margin-top: 50px !important;
	}
	.bg-mob{
		background-image: url('./Assets/Latest/b.png');
		margin-top: -15px !important;
	}
	.btn-home-download{
		margin-left: 0px !important;
	}
	.top-spacex{
		margin-top: 50px !important;
	}
	.aboutus-section {
    padding: 10px 0px 80px 0px !important;
	}
	.what-we-offer {
    padding: 60px 0px 30px 0px !important;
	}
	.navbar-main {
    padding: 3px 0px;
	}
	.common-banner {
		padding: 35% 0px 9% 0px;
	}
	.exclusv-half {
    width: 80%;
	}
	.exclusive-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
	}
	.inner-space {
    padding-top: 143px;
	}
	.heroo{
		line-height: 1.2;
		font-size: 34px;
	}
	.marquee {
		margin-top: -11px !important;
		padding: 7% 0px 5% 0px;
	}
	.about-image {
    width: 75%;
    display: table;
    margin: auto;
	}
	.btmm{
		padding-bottom: 45px;
	}
	.title-about-us {
    padding-bottom: 6px;
	}
	.title-about-us-main {
    padding-bottom: 7px;
	}
	.sub-t-about-us {
    font-size: 18px;
}
.btn-home-explore {
	padding: 12px 40px 12px 40px !important;
	display: block;
	margin-right: auto;
	margin: unset;
	margin-bottom: 30px !important;
}
.btn-home-download-a {
	margin-left: 0px !important;
	}


	.subb-title-aboutt {
    font-size: 14px;
    padding-bottom: 30px;
    text-align: justify;
	}
	.about-sectionn {
    padding-top: 36px;
    padding-bottom: 55px;
	}
	.qualities-box {
    min-height: unset;
	}
	.sub-title-hero {
    padding-bottom: 20px;
    font-size: 14px;
    padding-bottom: 10px;
    color: #2A2D77 !important;
	}
	.best {
    padding-bottom: 33px;
	}
	.clients-box{
		width: 98% !important;
	}
	.qualities {
    padding-bottom: 25px;
	}
	.about-img{
		margin-bottom: 30px;
	}
	.why-choose-sub-text {
    font-size: 14px;
	}
	.footer-inner{
		margin-bottom: 30px;
	}
	.footer-inner ul li {
    padding-bottom: 10px;
	}
	.footer-sub-text {
    font-size: 15px;
    width: 77%;
    padding-top: 29px;
	}
	.footer-logo {
    width: 35%;
    margin: auto;
	}
	.contact-main{
		padding-bottom: 40px;
	}
}
@media (max-width:500px){
	.footer-copyright p {
		font-size: 14px;
		padding-top: 13px;
	}
	.contact-details {
    font-size: 14px;
	}
	.page-title {
    font-size: 24px;
	}
	.page-name {
    font-size: 19px;
    margin-bottom: 14px !important;
	}
	.get-in {
    font-size: 17px;
    margin-bottom: 24px !important;
	}
	.icon-footer{
		font-size: 15px;
	}
	.customers-box h3 {
    font-size: 16px;
	}
	.our-products-sub-title {
    font-size: 14px;
	}
	.sub-title-about{
    font-size: 14px;
	}
}
@media (max-width:400px){
	.heroo {
		line-height: 1.2;
		font-size: 34px;
	}
	.sub-hero {
		font-size: 16px;
		width: 100%;
	}
	.btn-home-download{
		display: block !important;
		width: 200px !important;
		text-align: center !important;
		margin-top: 30px !important;
		margin-top: 15px !important;
		margin-left: 0px !important;
	}
	.sub-t-about-us {
		font-size: 16px;
		text-align: justify;
	}
	.btn-home-download-a {
		display: block !important;
		margin-top: 10px !important;
		width: 200px !important;
		text-align: center !important;
    margin-left: 0px !important;
    text-decoration: none !important;
    padding: 11px 20px 12px 20px !important;
	}
	.aboutus-section {
    padding: 80px 0px 43px 0px;
	}
	.aboutus-section {
    padding: 80px 0px 39px 0px;
	}
	.why-shop {
    padding: 30px 0px 30px 0px;
	}
	.what-we-offer {
    padding: 62px 0px 33px 0px;
	}
	.value-img {
		width: 98%;
		margin-top: 6px;
	}
	.logo-img {
		width: 87px;
	}
	.btn-home-explore{
		display: block !important;
		margin: unset !important;
		margin-right: auto !important;
		width: 200px !important;
		text-align: center !important;
	}
}




